@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/@mdxeditor/editor/dist/style.css';

/* :root {
  font-family: Arial, Helvetica, sans-serif;
} */

:root {
  font-family: "apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.bodytextsize {
  @apply text-[14px];
}

.fn__token_info{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	position: relative;
}

.fn__token_info .token_summary{
	border-radius: 5px;
	line-height: 16px;
	height: 30px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 5px 12px;
}

.fn__token_info .count{
	font-weight: 600;
	/* font-size: 18px; */
	padding-right: 25px;
	position: relative;
}
.fn__token_info .count:after{
	content: '';
	position: absolute;
	right: 12px;
	top: 50%;
	margin-top: -10px;
	height: 20px;
	background-color: #413e45;
	width: 1px;
}

.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.gradient-text {
	@apply bg-clip-text text-transparent bg-gradient-to-tr from-[#24BF86] via-[#37cdbe] to-[#24BF86];
  }

.swiper {
  width: 100%;
  height: '100px';
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: '100px';
    object-fit: cover;
}